export const GrowingData =[
    {
        img:require("../Assests/Activity2/gro/gr1.jpg"),
        title:"  مشروع تشجير الاسطح",

    },
    {
        img:require("../Assests/Activity2/gro/gr2.jpg"),
        title:"  مشروع تشجير الاسطح",

    },
    {
        img:require("../Assests/Activity2/gro/re4.jpg"),
        title:"  اعاده التدوير",

    },

    {
        img:require("../Assests/Activity2/gro/re2.jpg"),
        title:"  اعاده التدوير",

    },
    {
        img:require("../Assests/Activity2/environment/en1.jpg"),
        title:" ندوه للحفاظ على البيئه",

    },

    {
        img:require("../Assests/Activity2/environment/en3.jpg"),
        title:" ندوه للحفاظ على البيئه",

    },
    {
        img:require("../Assests/Activity2/environment/en4.jpg"),
        title:" ندوه للحفاظ على البيئه",

    },
    {
        img:require("../Assests/Activity2/environment/en5.jpg"),
        title:" ندوه للحفاظ على البيئه",

    },
    {
        img:require("../Assests/Activity2/environment/en2.jpg"),
        title:" ندوه للحفاظ على البيئه",

    },
]
