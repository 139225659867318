
import Card from "../Component/Card";
import { HelpData } from "./HelpData";
export default function Help(){

        const dataShow = HelpData.map((item , index) => (
            <div  className="card">
                <Card img={item.img} title={item.title} key={index} />
            </div>
        ));
        return (
            <div>
                <h5 className="title">  المساعدات </h5>
              <div className="comCard">{dataShow}</div>
            </div>
        )
    
}