export const ComputerData =[
    {
        img:require("../Assests/computer/computer2017.jpg"),
        title:"دوره كمبيوتر 2017",

    },
    {
        img:require("../Assests/computer/computer2021.jpg"),
        title:"دوره كمبيوتر 2021",

    },
    {
        img:require("../Assests/computer/computer_7_2021.jpg"),
        title:"دوره كمبيوتر 2021",

    },
    {
        img:require("../Assests/computer/computer2021.jpg"),
        title:"دوره كمبيوتر 2021",

    },
    {
        img:require("../Assests/computer/computer2022.jpg"),
        title:"دوره كمبيوتر 2022",

    },
    {
        img:require("../Assests/computer/computer_8_2023.jpg"),
        title:"دوره كمبيوتر 2023",

    },
]