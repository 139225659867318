import Card from "../Component/Card";
import { EnvironmentData } from "./EnvironmenData";
export default function Environment(){

                    const dataShow = EnvironmentData.map((item, index) => (
                        <div  className="card">
                            <Card img={item.img} title={item.title}  key={index} />
                        </div>
                    ));
                    return (
                        <div>
                            <h5 className="title">  الاعمال اليدويه  </h5>
                          <div className="comCard">{dataShow}</div>
                        </div>
                    )
                
        
    }