import { Link } from 'react-router-dom';
export default function Shopping(){
    return(
        <div className="Shopping">
            <h4> مسئول  <b style={{ color: "#1A5B92", fontSize:"30px", }}> التسويق</b>       ييهتم بالتفاصيل والمهارات التقنية والتفكير الابداعي وقيادة فريق العمل ويؤكد علي أخلاقيات عمل صارمة ومهارات تواصل ممتازة وفكر ابداعي  للترويج لمنتجات وخدمات ومشاريع الجمعية  ..   </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>	الترويج للمشاريع والمنتجات التي تنفذها الجمعية والتأكيد علي التدريب الذي تقدمه الجمعية لعملائها     وتاثيرها في المجتمع المحلي والعمل علي تنمية قاعدة عملائهاوالتأكيد علي دور المساعدات الاغاثية النقدية والعينية في مساندة المجتمع المحلي والبيئة المحيطة</p>
                </li>
                <li>
                    <p>	العمل مع فريق عمل من المتطوعين وتحفيزهم ليكونوا ذوو تأثير عند اصحاب المصالح</p>
                </li>
                <li>
                    <p>	التواصل مع العملاء وأصحاب المصالح والحرص علي وجود علاقة طيبة تربطهم بالجمعية</p>
                </li>
                <li>
                    <p>	الاستخدام الامثل لوسائل التقنية الحديثة والنت ووسائل التواصل الاجتماعي للوصول الي اكبر قدر ممكن من العملاء وأصحاب المصالح وإقناعهم بأهداف الجمعية وأهميتها حتي يتبنونها ويدعمونها</p>
                </li>
                </ol>
            </ul>
                        <div className="img">
            <div className='imgeS' ></div>
            <p> صور من الحملات التسوقيه</p>
            <Link to="/تسويق" className='link'> معرفه المزيد </Link>
        </div>
        </div>
    )

}