import Card from "../Component/Card";
import { SewingData } from "./SewingData";

export default function Sewing() {
    const dataShow = SewingData.map((item , index) => (
        <div className="card">
            <Card img={item.img} title={item.title} key={index} />
        </div>
    ));

    return (
        <div>
            <h5 className="title"> دورات الخياطه  </h5>
            <div className="comCard">{dataShow}</div>
        </div>
    );
}