export const HelpData =[
    {
        img:require("../Assests/Activity2/feed/1.JPG"),
        title:"افطار رمضان  ",

    },
    {
        img:require("../Assests/Activity2/feed/2.jpg"),
        title:"افطار رمضان  ",

    },
    {
        img:require("../Assests/Activity2/feed/3.jpg"),
        title:"افطار رمضان  ",

    },
]