import Card from "../Component/Card";
import { cooData } from "./cooData";
export default function coo(){

                    const dataShow = cooData.map((item, index) => (
                        <div  className="card">
                            <Card img={item.img} title={item.title}  key={index} />
                        </div>
                    ));
                    return (
                        <div>
                            <h5 className="title"> صور من الشراكه مع جمعيه الرساله   </h5>
                        <div className="comCard">{dataShow}</div>
                        </div>
                    )
                
        
    }