

export default function Seeing() {
    return (
        <div className="Seeing mt-5 m-3">
            <h2 className="mb-3"> الرؤية والرسالة والاهداف الاستراتيجية </h2>

            <h5>	 <span><b> 	الرؤيه : </b></span>  "تقديم الدعم للمجتمع المحلي حتي لايوجد فقير محتاج"</h5>
            <h5>	 <span><b> 	الرساله : </b></span> "تنمية المجتمع من خلال تقديم الخدمات الثقافية والاجتماعية والاقتصادية"</h5>
            <p><b> 	القيم : </b></p>
            <ul >
                <ol type="1" style={{ fontSize: '20px', color: 'black' }}>
                    <li >
                        <p style={{ fontSize: '20px', color: 'black' }}>	الشفافية: نعتمد على الشفافية في تنفيذ أنشطة الجمعية</p>
                    </li>
                    <li>
                        <p style={{ fontSize: '20px', color: 'black' }}>	المصداقية: نسعي الي تحقيق مانتفق عليه مع العملاء ومع المانحين</p>
                    </li>
                    <li>
                        <p style={{ fontSize: '20px', color: 'black' }}>	الجودة:  نسعي الي تحقيق الجودة في الخدمات المقدمة	</p>
                    </li>
                    <li>
                        <p style={{ fontSize: '20px', color: 'black' }}>	خصوصية الفئات المستهدفة: مسئوليتنا الحفاظ علي خصوصية عملائنا	</p>
                    </li>
                    <li>
                        <p style={{ fontSize: '20px', color: 'black' }}>	تكافؤ الفرص : نهتم بتعزيز مبدأ المساواة في توزيع الفرص بين جميع الفئات العاملة</p>
                    </li>
                    <li>
                        <p style={{ fontSize: '20px', color: 'black' }}>	الانضباط والالتزام: نعمل على تحقيق مستوى عال من الالتزام والانضباط في أعمالنا	</p>
                    </li>
                    <li>
                        <p style={{ fontSize: '20px', color: 'black' }}>	عدم التحزب والفئوية: نعمل دون تحيز ألي فئة أو حزب معين فالجميع سواسية</p>
                    </li>
                    <li>
                        <p style={{ fontSize: '20px', color: 'black' }}>	مراعاة قيم المجتمع وعاداته:  نعمل وفق قيم وعادات المجتمع المصري الاصيلة الذي نحن جزء منه	</p>
                    </li>
                    <li>
                        <p style={{ fontSize: '20px', color: 'black' }}>	المسئولية الاجتماعية:  نضع على عاتقنا المسؤولية تجاه جميع الفئات المستهدفة 	</p>
                    </li>
                    <li>
                        <p style={{ fontSize: '20px', color: 'black' }}>	التعاون:  نعزز التعاون والعمل بروح الفريق بين العاملين بالجمعية	</p>
                    </li>
                </ol>
            </ul>
            <h5>	 الاهداف الاستراتيجية: </h5>
            <ul>
                <ol type="1">
                    <li>
                        <p>	التوعية:  نشر الوعي والمفاهيم الايجابية بالمجتمع المحلي</p>
                        <ul style={{ margin: '0px 40px' }}>
                            <li>
                                <p>الاهتمام بالتوعية في مجالات الصحة والتعليم والبيئة والمرأة والتنمية للمساهمة في تعزيز المفاهيم والاتجاهات الفكرية الإيجابية بالمجتمع المحلي والبيئة المحيطة عن طريق الندوات والمحاضرات وغيرها.</p>
                            </li>

                            <ul style={{ color: 'black', fontSize: '18px', margin: '5px 80px ' }}>
                                <li>ترسيخ مفاهيم الحوار العائلي ومبدأ الشراكة الزوجية.</li>
                                <li>تعزيز الدور الإيادي للشباب في بناء المجتمع.</li>
                                <li>تفعيل برامج التوعية وتنمية القدرات لتعزيز مشاركة المرأة في التنمية المجتمعية.</li>
                                <li>المساهمة في توفير بيئة صحية ملائمة للطفل لتنشئة جيل قادر على المشاركة في بناء مجتمع حضاري.</li>
                                <li>استثمار الخبرات والقدرات لكبار السن والمتقاعدين من خلال تعزيز مشاركتهم المجتمعية.</li>
                            </ul>

                            <li>
                                <p>رفع مستوى أداء الجمعية لتكون مؤسسة قادرة على الوفاء باحتياجات فئاتها المستهدفة والعمل على:</p>
                                <ul style={{ color: 'black', fontSize: '18px', margin: '5px 80px ' }}>
                                    <li>تطوير النظم الإدارية والمالية لضبط شؤون الجمعية.</li>
                                    <li>تطوير برنامج بناء قدرات الأفراد في الجمعية لزيادة وتطوير قدراتهم على العمل.</li>
                                    <li>تطوير الهيكل التنظيمي للجمعية وتشجيع مشاركة العاملين في صنع القرار.</li>
                                    <li>تطوير نظام المراقبة والتقييم بشكل مستمر لتحسين الأداء.</li>
                                    <li>تعزيز الصورة الإعتبارية للجمعية وتفعيل أنشطة العلاقات العامة والإعلام.</li>
                                    <li>استمرارية تقديم الخدمات لفئات الجمعية المستهدفة.</li>
                                    <li>تطوير خطة توفير الأموال لسد الاحتياجات المالية لتقديم الخدمات الأساسية للفئات.</li>
                                    <li>تطوير خبرات ومهارات الجمعية في مجال كتابة المشاريع وتجنيد الأموال.</li>
                                </ul>
                            </li>
                            <li>
                                <p>الاستعانة بفنانين تشكيليين وأداء لنشر الوعي الفني بين الأطفال والآباء.</p>
                            </li>
                            <li>
                                <p>إقامة صالون ثقافي بالجمعية بصفة دورية لرفع المستوى الفني والأدبي والثقافي للأهالي.</p>
                            </li>
                            <li>
                                <p>ندوات علمية في مجالات العلوم المختلفة والتقنيات الحديثة للتأكيد على استخدام الأهالي.</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>	رفع  المستوي الاقتصادي للمجتمع المحلي والاهتمام بالعنصر البشري والتنمية البشرية وزيادة دخل الاسرة </p>
                        <ul style={{ margin: '0px 40px' }}>
                            <li>
                                <p>رفع المستوى الاقتصادي للمجتمع المحلي والاهتمام بالعنصر البشري والتنمية البشرية وزيادة دخل الأسرة</p>
                                <ul>
                                    <li>
                                        <p>يقوم على فكرة تطوير الإنسان من خلال التدريب التقني والمهني الذي يلبي متطلبات السوق وتطوير الكفاءات والمحلية ومايتطلبه ذلك من الشراكات مع مراكز التدريب الحكومية والخاصة بالإضافة إلى جلب مدربين داخل جمعيات المبادرة لمساعدة الشباب والمرأة على زيادة قدراتهم الحرفية والمهنية وتعظيم الاستفادة من الموارد الطبيعية والبشرية المتاحة</p>
                                    </li>
                                    <li>
                                        <p>دعم وتشجيع المشروعات متناهية الصغر المعتمدة على أفكار إبتكارية وتحمي وتحافظ على البيئة وإعداد كوادر فنية مؤهلة ومتخصصة ومدربة على التكنولوجيا الحديثة في المجالات المختلفة. تنمية قدرات ومهارات الشباب خاصة الحرفيين منهم ودعمهم لإدارة مشروعات خاصة بهم ليكونوا رواداً للأعمال وقادة لمستقبل الوطن</p>
                                    </li>
                                    <li>
                                        <p>تهدف المبادرة إلى تدريب وتأهيل أهالي المنطقة من الفئة العمرية من 18 إلى 30 من الجنسين. مجالات التدريب والمشروعات بالمبادرة على النحو التالي:
                                            <ul style={{ margin: '5px 80px' }}>
                                                <li>البرادة واللحام والحدادة والسباكة</li>
                                                <li>مجال التشيد والبناء مثل كهرباء، نجارة، دهانات، سيراميك</li>
                                                <li>صناعة الملابس الجاهزة مثل الخياطة والقص والتفصيل. يوجد بجمعية الشرق مشغل خياطة</li>
                                                <li>الحرف اليدوية والتراثية مثل المكرمية، الكروشيه</li>
                                                <li>إعادة تدوير مثل صناعة الأثاث من البراميل الصاج ومن إطارات السيارات والسجاد والكليم اليدوي والنول وزراعة الأسطح</li>
                                            </ul>
                                        </p>
                                    </li>
                                    <li>
                                        <p>كذلك تهدف المبادرة إلى توفير فرص عمل من خلال التوظيف لدي الغير والتشغيل الذاتي ومن خلال إنشاء المشاريع الصغيرة الخاصة بهم.
                                            تشجيع أصحاب المشاريع على إعداد دراسات جدوى لمشاريعهم ومساعدتهم في الإعداد والتنفيذ.
                                            مطلوب حصر للفئات المطلوب تدريبها أو عمل مشروعات لها.
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>رفع المستوي الاجتماعي للمجتمع المحلي والاهتمام بالتعليم والصحة والمرآة والشباب والمسنين وذوي الهمم</p>
                        <ul style={{ margin: '0px 40px' }}>
                            <li>
                                <p>الارتقاء بالمستوى التعليمي للفئات في سن التعليم وتحديد الفئات المتسربة من التعليم وتحديد المسارات لمعاودة التعليم المجتمعي أو محو الأمية أو العمل الفني لهم حسب الحالة.</p>
                            </li>
                            <li>
                                <p>التعاون مع المدارس بأنواعها بالمجتمع المحلي والعمل على زيادة قدرتها على تقديم الخدمة التعليمية المتميزة وفقًا لتكنولوجيا التعليم الحديث.</p>
                            </li>
                            <li>
                                <p>الاهتمام بالأطفال في سن التعليم الابتدائي والإعدادي وإعطائهم دروس تأسيس في اللغة العربية واللغة الإنجليزية والرياضيات والاهتمام بالأنشطة التي تزيد من ذكاء الأطفال والاهتمام بالفنون والرسم والموسيقى والبحث عن المواهب والاهتمام بهم.</p>
                            </li>
                            <li>
                                <p>التعاون مع مكتب صحة عرب غنيم ودراسة إمكانياته وإمكانية تطويره وزيادة قدراته.</p>
                            </li>
                            <li>
                                <p>العمل مع الجهات المختصة لإنشاء مكتب صحة بمنطقة الملاءة لتقديم الخدمة الصحية لأهالي المنطقة.</p>
                            </li>
                            <li>
                                <p>التعاون مع المبادرات الرئاسية الخاصة بالصحة العامة وتعريف الأهالي بها والاستفادة منها.</p>
                            </li>
                            <li>
                                <p>التعاون مع إدارة الطب البيطري بحلوان لخدمة أهالي المنطقة.</p>
                            </li>
                            <li>
                                <p>الاهتمام بالمرأة المعيلة والمرأة العاملة والعمل على دعمها وزيادة قدراتها.</p>
                            </li>
                            <li>
                                <p>الاهتمام بالشباب ودراسة مشاكلهم بالمنطقة ووضع الحلول العملية لها من جانب المختصين.</p>
                            </li>
                            <li>
                                <p>الاهتمام بالأطفال والعمل على رفع مستواهم الفكري والثقافي والفني والأدبي والعلمي.</p>
                            </li>
                            <li>
                                <p>رعاية المسنين وتقديم يد العون لهم.</p>
                            </li>
                            <li>
                                <p>رعاية ذوي الهمم والاهتمام بالأنشطة التي ترفع من نسبة ذكائهم.</p>
                            </li>
                        </ul>
                    </li>
                    <li style={{ fontSize: '20px', color: 'black' }}>
                        <p >  حماية البيئة والمحافظة عليها </p>
                        <p style={{ color: 'black', fontSize: '19px', }}>في إطار توجه الدولة والمجتمع الدولي إلى الاهتمام بالتغيرات المناخية، تهدف المبادرة إلى إنشاء مجمع بيئي بالمنطقة المسورة بمدخل عرب غنيم 900 متر يقوم على عمل نماذج لمشروعات بيئية تهدف إلى حماية البيئة والمحافظة عليها مثل الزراعة المائية والسماد العضوي وتدوير المخلفات الصلبة وغيرها من المشروعات التي تخدم الأهالي بالمنطقة.</p>
                        <p style={{ color: 'black', fontSize: '19px', }}>وتهدف المبادرة أيضًا إلى مساعدة الراغبين في إقامة مشروعات بهدف تحقيق عائد مادي وربح، مما يعود بالنماء والخير على أهالي المنطقة، ويمكن من العائد لهذه المشروعات تطوير وتنمية المنطقة من النواحي البيئية والاقتصادية والتعليمية والصحية.</p>
                        <p style={{ color: 'black', fontSize: '19px', }}>يمكن الاستعانة بجامعة حلوان ووزارة البيئة والجهات التنفيذية المختصة لتنفيذ هذه المشروعات بما يضمن تحقيق الأهداف المنشودة.</p>
                        <h3 style={{ color: 'gray', fontSize: '19px', }}>مشروعات تقوم على المخلفات الصلبة:</h3>
                        <ul style={{ color: 'black', fontSize: '19px', margin: '0px 50px' }}>
                            <li>الأثاث من البراميل الصاج والكراسي من إطارات السيارات.</li>
                            <li>تدوير الكرتون والورق والبلاستيك وعلب الكانز.</li>
                        </ul>
                        <h3 style={{ color: 'gray', fontSize: '19px' }}>استخدام المخلفات العضوية وبقايا المطبخ في:</h3>
                        <ul style={{ color: 'black', fontSize: '19px', margin: '0px 50px' }}>
                            <li>تربية وزراعة دود الريدويجلر وإنتاج الفيرم كومبوست.</li>
                            <li>استخراج البيوغاز من مخلفات الحيوانات.</li>
                        </ul>
                        <h3 style={{ color: 'gray', fontSize: '19px' }}>تنمية وتطوير تجربة زراعة الأسطح:</h3>
                        <p style={{ color: 'black', fontSize: '19px', margin: '0px 10px' }}>يمكن استهداف 100 سطح لزراعته بأقل امكانيات ممكنة، ويمكن استخدام كراكيب السطح بالاستفادة منها في عمل أوعية للزراعة بها واستخدام تربة بديلة لزراعة نباتات أورجانيك للأسر بالمنطقة. يمكن الاستعانة بمعمل المناخ بكلية الزراعة والمتخصصين في هذا المجال بجامعة حلوان لجعلها جامعة خضراء.</p>

                    </li>
                </ol>
            </ul>

        </div>
    )
}