export const hummanData =[
    {
        img:require("../Assests/humman/1.jpg"),
        title:" الموارد البشريه  ",

    },
    {
        img:require("../Assests/humman/2.jpg"),
        title:" الموارد البشريه  ",

    },
        {
        img:require("../Assests/humman/3.jpg"),
        title:" الموارد البشريه  ",

    },
]