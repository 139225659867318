export const selllData =[
    {
        img:require("../Assests/sell/1.jpg"),
        title:" صور من دورات  الحملات التسوقيه",

    },

            {
        img:require("../Assests/sell/2.jpg"),
        title:" صور من دورات  الحملات التسوقيه",

    },
        {
        img:require("../Assests/sell/3.jpg"),
        title:" صور من دورات  الحملات التسوقيه",

    },
        {
        img:require("../Assests/sell/4.jpg"),
        title:" صور من دورات  الحملات التسوقيه",

    },
        {
        img:require("../Assests/sell/5.jpg"),
        title:" صور من دورات  الحملات التسوقيه",

    },
        {
        img:require("../Assests/sell/6.jpg"),
        title:" صور من دورات  الحملات التسوقيه",

    },
        {
        img:require("../Assests/sell/7.jpg"),
        title:" صور من دورات  الحملات التسوقيه",

    },
        {
        img:require("../Assests/sell/8.jpg"),
        title:" صور من دورات  الحملات التسوقيه",

    },
        {
        img:require("../Assests/sell/9.jpg"),
        title:" صور من دورات  الحملات التسوقيه",

    },
]