 import { Link } from "react-router-dom";

export default function Care(){
    return(
        <div className="Care">
          <h4>المسئول عن   <b style={{ color: "#1A5B92", fontSize:"30px", }}>التوعية</b>   وله خبرة وافية والمام واسع بالثقافة العامة ويعمل علي ترتيب المكتبة الورقية والمكتبة  الالكترونية بالجمعية وزيادة امكانياتها مع التوسع في لاب الكمبيوتر والاستفادة من الامكانيات المتوفرة من أجهزة ومتطوعين لخدمة الاهالي وخاصة الشباب والاطفال ومحاربة ظاهرة التسرب من التعليم والاشراف علي الدورات المختلفة للتوعية  وخاصة في مجال المرآة  والتمكين الاقتصادي والاجتماعي والتأسيس وغيرها  ويكون لديه فكرة متكاملة عن كيفية تأثير الجمعية في المجتمع المحلي والبيئة المحيطة   </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>	وضع خطة للتأثير الفكري في المجتمع المحلي بنشر مبادئ التسامح والتعاون المشترك والبعد عن أفكار العنف  </p>
                </li>
                <li>
                    <p>تنفيذ محاضرات التوعية في الجوانب المجتمعية والعلمية والبيئية 	</p>
                </li>
                <li>
                    <p>	العمل علي جذب الآهالي خاصة فئة الشباب والاطفال الي الاطلاع والاستعارة </p>
                </li>
                <li>
                    <p>	اقامة الرحلات العلمية والترفيهية للتعارف بين الاهالي خاصة الاطفال	</p>
                </li>
                <li>
                    <p>	اقامة دورات تدريبية للحاسب الالي والتوعية بأهمية ومخاطر التحول الرقمي والميتافيرس </p>
                </li>
                <li>
                    <p> 	اقامة اللقاءات  والصالونات الثقافية والمشاركة في الفعاليات الثقافية لخدمة اهالي المنطقة</p>
                </li>
                <li>
                    <p>	التواصل مع الجهات الثقافية لخدمة أهداف الجمعية وخدمة الاهالي في الجوانب الفكرية والثقافية </p>
                </li>
                <li>
                    <p>	التعاون مع الجهات المختلفة التي تعمل في مجال الثقافة والفكر والفن والادب لعقد بروتوكولات عمل معهم تساعد وتدعم مسيرة وأهداف الجمعية  </p>
                </li>
                </ol>
            </ul>
            <div className="img">
            <div className='imgeT' ></div>
            <p> صور من ندوات العنف ضد المراءه</p>
            <Link to="/العنف_ضد_المراه" className='link'> معرفه المزيد </Link>
        </div>
        </div>
    )
}

