export default function Product(){
    return(
        <div className="Product">
            <h2> قسم الموارد :</h2>
            <h4> متابعة تنفيذ الأنشطة المرتبطة بعمل الجمعية و المشاركة في وضع الخطط والبرامج الخاصة بالتنسيق والشراكات مع الجهات المختلفة  وخاصة تلك التي تتمثل في  بناء الشراكات مع أصحاب العمل بغرض توفير فرص التوظيف والتدريب الملائمة للراغبين من أهالي المنطقة. رسم خطة للترويج لمنتجات وخدمات ومشاريع الجمعية</h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>	وضع الخطط اللازمة للترويج لمنتجات وخدمات ومشاريع الجمعية</p>
                </li>
                <li>
                    <p>	التواصل لبناء الشراكات مع  أصحاب العمل بغرض توفير فرص توظيف وتدريب ملائمة عبر شراكات محدد</p>
                </li>
                <li>
                    <p>	وضع خطة لتطوير برامج تدريبية للمواطنين من خلال شراكات استراتيجية مع الجهات المعنية بالتدريب داخل الدولة</p>
                </li>
                <li>
                    <p>	إنشاء شراكات فعالة مع االجهات المانحة الدولية و المحلية والخاصة ذات الصلة لخدمة أهالي المنطقة </p>
                </li>
                <li>
                    <p>	التنسيق والتواصل مع شركاء الجمعية وأصحاب المصالح  على صعيد تنمية وتوظيف الموارد البشرية </p>
                </li>
                <li>
                    <p>	تعزيز مفهوم المسؤولية المجتمعية والإشراف على تفعيلها مع أصحاب المصالح  ورفع مستوى الوعي المجتمعي</p>
                </li>
                </ol>
            </ul>

        </div>
    )
}