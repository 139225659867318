import { Link } from 'react-router-dom';
export default function SocialReserch(){
    return(
        <div className="SocialReserch">
            <h4>المسئول  <b style={{ color: "#1A5B92", fontSize:"30px", }}>البحوث الاجتماعية والمساعدات</b>    ويكون علي دراية بالبحث الاجتماعي والاستبيانات والاستكشافات ويعمل علي تجميع المعلومات والبيانات اللازمة عن عملاء الجمعية  المستفيدين والمستحقين لخدماتها وتقديم كل المساعدات الممكنة لهم  والعمل علي تحقيق الرضا والسسعاذة لهم جميعاً</h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>	إعداد وتطوير نماذج الاستبيانات والاستكشافات الخاصة بجمع البيانات والمعلومات عن المستفيدين وتسجيلها والاحتفاظ بها في سجلات المستفيدين بالجمعية </p>
                </li>
                <li>
                    <p>	تدريب المتطوعين علي اساليب جمع البيانات من المقابلة والملاحظة والمشاركة السريعة والبحث الميداني والمكتبي وكيفية استيفاء نماذج الاستبيانات والاستكشافات للعمل بها</p>
                </li>
                <li>
                    <p>	متابعة الابحاث المكتبية والميدانية للمستفيدين الجدد وتسجيل مدي استحقاقهم للخدمة ونوعية الخدمة المطلوبة   </p>
                </li>
                <li>
                    <p>	أجراء الابحاث الدورية للمستفيدين من خدمات الجمعية الذين تتعامل معم الجمعية  لمعرفة تطور الحالة  ومدى احقيتها للمساعدة ومدي حاجتها حالياُ للمساعدة من عدمه ونوعيتها</p>
                </li>
                <li>
                    <p>	التواصل مع الجهات المسئولة في هذا المجال والجمعيات الشقيقة للمساعدة في الابحاث ومعرفة مدي مساعدتهم لطالبي الاستفادة من خدمات الجمعية من عدمه</p>
                </li>
                <li>
                    <p>	تقديم كل انواع المساعدات للمستفيدين ومحاولة تحقيق الرضا والسعادة لهم.قدر المستطاع وفقا لامكانات الجمعية</p>
                </li>
                </ol>
            </ul>
            <div className="cooo">
            <div className="img" >
            <div className='imgeRR1' ></div>
            <p> افطار رمضان</p>
            <Link to="/المساعدات" className='link'> معرفه المزيد </Link>
            </div>
            <div className="img"  >
            <div className='imgeRR' ></div>
            <p> الرعايه الصحيه</p>
            <Link to="/الرعايه_الصحيه" className='link'> معرفه المزيد </Link>
        </div>
        </div>
        </div>
    )

}