
export default function Projects(){
    return(
        <div className="Projects">
            <h2> قسم المشروعات : </h2>
            <h4> توفير البيئة المناسبة لاقامة مشروعات متناهية الصغر تحتضنها الجمعية والتخطيط لتنفيذ برامج لمشروعات داخل الجمعية ورسم الخطط المطلوبة للتعامل مع الجهات المانحة في مشروعات تسند للجمعية</h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>	البحث عن الصفقات والفرص التى توفر التمويل للمشروعات المخطط لها للجمعية وعملائها من الراغبين فى اقامة المشاريع.. أو المساعدة فى ازالة تعسر مشروعات قائمة</p>
                </li>
                <li>
                    <p>	البحث والتواصل مع الجهات المانحة من الوزارات والهيئات والجهات الاخرى خاصة أن الجمعية لها صفة النفع العام </p>
                </li>
                <li>
                    <p>	اعداد التقارير المتعلقة بما قامت به الجمعية من مشاريع متناهية الصغر أو شاركت أو ساهمت فيها. وفقاً للاسلوب الذى يتماهى مع نماذج تتعامل بها الجهات المانحة</p>
                </li>
                <li>
                    <p>	تشجيع رجال الاعمال والجهات المانحة من دعم الجمعية فى مساعدة الشباب والمرآة المعيلة فى اقامة المشروعات داخل الجمعية أو مشروعات خاصة بالأفراد بعد اعداد دراسات جدوى لها أو حتى عن طريق التمويل الذاتي بالجمعية </p>
                </li>
                <li>
                    <p>	الاهتمام بالمشروعات التى تحافظ على البيئة والتغيرات المناخية  وتلك التى تركز على التكنولوجيا الحديثة وكذلك التى تهتم بالمشغولات اليدوية وتساعد على زيادة دخل الاسرة</p>
                </li>
                <li>
                    <p>	مساعدة الشباب والمرآة المعيلة علي اقامة المشروعات متناهية الصغر في المجالات المختلفة مثل الخياطة والتفصيل والاعمال اليدوية</p>
                </li>
                </ol>
            </ul>
            <div className="cooo">
            <div className="img" >
            <div className='imgeP1' ></div>
            </div>
            <div className="img"  >
            <div className='imgeP' ></div>
        </div>
        </div>

        </div>

        
    )
}