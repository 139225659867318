export const WomanData =[
    {
        img:require("../Assests/woman/1.jpg"),
        title:"  بالقصر الثقافى "

    },



    {
        img:require("../Assests/woman/3.jpg"),
        title:"جمعيه معاكم ",

    },
    {
        img:require("../Assests/woman/9.jpg"),
        title:" جمعيه معاكم ",

    },
    {
        img:require("../Assests/woman/2.jpg"),
        title:"  نادى الجمعيه الشرعيه وجمعيه نور الايمان  ",

    },
    {
        img:require("../Assests/woman/4.jpg"),
        title:" مدرسه الصنايع بعين حلوان   ",
    },
    {
        img:require("../Assests/woman/5.jpg"),
        title:" مدرسه الصنايع بعين حلوان   ",

    },
    {
        img:require("../Assests/woman/6.jpg"),
        title:" مدرسه الصنايع بعين حلوان   ",

    },
    {
        img:require("../Assests/woman/7.jpg"),
        title:" مدرسه الصنايع بعين حلوان   ",

    },
    {
        img:require("../Assests/woman/8.jpg"),
        title:" اصحاب مكملين ",

    },
]