import Card from "../Component/Card";
import { WomanData } from "./WomanData";
export default function Woman(){
           
            
                    const dataShow = WomanData.map((item, index) => (
                        <div  className="card">
                            <Card img={item.img} title={item.title}  key={index} />
                        </div>
                    ));
                    return (
                        <div>
                            <h5 className="title"> حمله العنف ضد المراءه  </h5>
                          <div className="comCard">{dataShow}</div>
                        </div>
                    )
                
        
    }