import { Link } from 'react-router-dom';
export default function HumanResourse(){
    return (
        <div className="HumanResourse">
            <h4> المسئول عن <b style={{ color: "#1A5B92", fontSize:"30px",}}>الموارد البشرية</b>  ويقوم بعملية الاختيار والتوظيف للعاملين بالجمعية  وادارة شئون العاملين والاشراف على برامج التدريب والتطوير الخاصة بهم وبناء قدرات الأفراد العاملين بالجمعية </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p> القيام باجراءات التوظيف للعاملين بالجمعية، والامساك بالسجلات ح ص مكافأت بيانات</p>
                </li>
                <li>
                    <p> تطبيق اجراء تقييم العاملين وبرامج تدريبهم علي العمل</p>
                </li>
                <li>
                    <p> نشر روح التعاون بين فريق عمل الجمعية كاسرة واحدة لتحقيق شعار الجمعية معا لنبني حياة أفضل </p>
                </li>
                <li>
                    <p> تعزيز الاعتماد علي الذات بالنسبة للعاملين والمتطوعين والعملاء للجمعية</p>
                </li>
                </ol>
            </ul>
            <div className="img">
            <div className='imgeH' ></div>
            <p> صور عن الموارد البشريه  </p>
            <Link to="/موارد_بشريه" className='link'> معرفه المزيد </Link>
        </div>
        </div>
    )
}