// import { Link } from "react-router-dom"
export default function Home(){
    return(
        <div className="Home mt-5 m-3 mb-3">
            <h5>    أهم الأحداث <i class="fa-regular fa-calendar-check" ></i></h5> 
            <p>يتم البدأ دورة ICDL من يوم السبت الموافق 17/2 من الساعه 3 عصرا حتى 6 مساءا</p>
            <p> بالشراكه مع 10 جمعيات من حلوان بدأ تدشين مبادره تنميه وتطوير حلوان </p>
            <p> مباداره العنف ضد المراءه من 25/11 الى 10/12 </p>
        </div>
    )
}