export default function Card(props){
    return (
        <div className="Card"> 
            <div className="image" style={{backgroundImage: `url(${props.img})`,
        }}></div>
        <div>
            <h4>{props.title}</h4>
            <p>{props.des}</p>
            </div>
        </div>
    )
}