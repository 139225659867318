export const SewingData =[
    {
        img:require("../Assests/sewing/sewing-dress.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing2.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing3.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing4.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing5.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing6.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing7.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing8.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing9.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing_2015.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing_2018.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing_2019.jpg"),
        title:" تدريبات الخياطه  ",

    },
    {
        img:require("../Assests/sewing/sewing_2_2019.jpg"),
        title:" تدريبات الخياطه  ",

    },
]