export default function Footer() {
    return (
        <div className="all m-0 col-12 ">
  <div className="footer m-0">

    <div className="alll col-5 col-md-3 order-6 order-md-8">
      <div className="allFo">
        <div className="navImageFo" alt="logo"></div>
        <div className="listItem">
          <p>جمعية الشرق الخيرية</p>
          <p>العنوان: عرب غنيم- حلوان - القاهرة</p>
        </div>
      </div>
    </div>
    <div className="social col-6 col-md-8 order-5 order-md-0 ">
      
      <div className="icons">
        <a href="https://web.facebook.com/groups/725358140852932/?_rdc=1&_rdr" rel="noopener noreferrer" target="_blank">
          <i class="fa fa-users" aria-hidden="true"></i>
      </a>


      <a href="https://web.facebook.com/people/%D8%AC%D9%85%D8%B9%D9%8A%D8%A9-%D8%A7%D9%84%D8%B4%D8%B1%D9%82-%D8%A7%D9%84%D8%AE%D9%8A%D8%B1%D9%8A%D8%A9-ElSharq/100068106684382/?mibextid=ZbWKwL" rel="noopener noreferrer" target="_blank">
        <i className="fa-brands fac fa-facebook"></i>
      </a>
      <a href="https://www.youtube.com/@elsharq-hb3bb" rel="noopener noreferrer" target="_blank">
        <i className="fa-brands you fa-youtube"></i>
      </a>
      <a href="https://www.youtube.com/@elsharq4605" rel="noopener noreferrer" target="_blank">
        <i className="fa-brands you fa-youtube"></i>
      </a>
      <a href="https://studio.youtube.com/channel/UCLkr1gD4kMt0cIHrB82hbpw/videos/upload?filter=%5B%5D&sort=%7B%22columnType%22%3A%22date%22%2C%22sortOrder%22%3A%22DESCENDING%22%7D " rel="noopener noreferrer" target="_blank">
        <i className="fa-brands you fa-youtube"></i>
      </a>

      </div>
    </div>
  </div>
  <p className="endFo">جميع الحقوق محفوظه@2023</p>
</div>

    )
}