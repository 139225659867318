// import JobList from './JobList';

export default function Jobs(){
    return(
    <div className="Jobs">
        <h4>المسئول  <b style={{ color: "#1A5B92", fontSize:"30px", }}>التوظيف</b>     يعمل علي البحث عن وظائف وإيجار فرص عمل للراغبين و التقليل من الآثار السلبية علي كل الفئات وخاصة تلك التي لاتملك مؤهلات علمية ولديها صعوبة كبيرة في الحصول علي وظيفة لدي الجهات التي تحتاج الي عمالة مما يحقيق مصلحة مشتركة للطرفين دون تحميل الراغب في العمل أية تكلفة مقابل التشغيل</h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p> وضع قوائم لشركات التوظيف المعتمدة وتحديثها باستمرار.والتواصل مع تلك الجهات لتوفير فرص عمل للراغبين والمحتاجين اليه </p>
                </li>
                <li>
                    <p>	وضع قوائم بيانات للراغبين في العمل من أهالي المنطقة والتحديث الدائم لبياناتهم  والبحث لهم عن وظيفة مناسبة ان امكن</p>
                </li>
                <li>
                    <p>	التواصل مع جهات التوظيف بالوزارات خاصة وزارة القوي العاملة  وكذلك الشركات والمصانع القريبة ومتابعة  مواقع التوظيف الجادة علي النت والتفاوض معهم للحصول علي وظائف ومهن للراغبين في العمل </p>
                </li>
                </ol>
            </ul>

    </div>
    )
}