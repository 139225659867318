// export default function TeamMember(){
const TeamMember = ({ name, position, bio, imageUrl }) => {
  return (
    <div className="row">
    <div className="team-member col-12 col-md-6">
      <img src={imageUrl} alt={name} className="member-image" />
      <div className="contantInfo">
      <h2 className="member-name">{name}</h2>
      <h3 className="member-position">{position}</h3>
      <p className="member-bio">{bio}</p>
    </div>
    </div>
    </div>
  );
};
// }
export default TeamMember;