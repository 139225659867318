import { Link } from 'react-router-dom';
export default function Companies(){
    return(
        <div className="Companies">
            <h4> مسئول   <b style={{ color: "#1A5B92", fontSize:"30px", }}>  الشراكات </b>     متابعة تنفيذ الأنشطة المرتبطة بعمل الجمعية و المشاركة في وضع الخطط والبرامج الخاصة بالتنسيق والشراكات  والتي تتمثل ببناء الشراكات مع أصحاب العمل بغرض توفير فرص التوظيف والتدريب الملائمة للراغبين من أهالي المنطقة  </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>	العمل على بناء الشراكات مع  أصحاب العمل بغرض توفير فرص توظيف وتدريب ملائمة عبر شراكات محدد</p>
                </li>
                <li>
                    <p>	العمل على تطوير برامج تدريبية للمواطنين من خلال شراكات استراتيجية مع الجهات المعنية بالتدريب داخل الدولة</p>
                </li>
                <li>
                    <p>	إنشاء شراكات فعالة مع االجهات المانحة الدولية و المحلية والخاصة ذات الصلة لخدمة أهالي المنطقة </p>
                </li>
                <li>
                    <p>	التنسيق والتواصل مع شركاء الجمعية وأصحاب المصالح  على صعيد تنمية وتوظيف الموارد البشرية</p>
                </li>
                <li>
                    <p>	العمل على تعزيز مفهوم المسؤولية المجتمعية والإشراف على تفعيلها مع أصحاب المصالح  ورفع مستوى الوعي المجتمعي  </p>
                </li>
                
                </ol>
            </ul>
            <div className="cooo">
            <div className="img" >
            <div className='imgeC' ></div>
            <p className='pra'> صور من الشراكه مع جمعيه الرساله</p>
            <Link to="/شراكات_الرساله" className='link'> معرفه المزيد </Link>
            </div>
            <div className="img"  >
            <div className='imgec2' ></div>
            <p className='pra'> صور من الشراكه مع جمعيات حلوان </p>
            <Link to="/شراكات_حلوان" className='link'> معرفه المزيد </Link>
        </div>
        </div>
        </div>
    )

}