
export default function Services(){
    return(
        <div className="Services">
            <h2> قسم الخدمات المساندة : </h2>
            <h4> تطوير نظم  الموارد البشرية والمالية في الجمعية والإشراف على تقديم كافة الخدمات الأساسية التي تحتاجها الجمعية لدعم أداء العاملين والمتطوعين ودعم الأنشطة التي تعزز الولاء الوظيفي ودعم تقنية المعلومات الحديثة والتحول الرقمي  ومتابعة الانشطة الاعلامية وتطويرادوات التواصل </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>	الإشراف على تطوير نظم  الموارد البشرية في الجمعية من تعيينات وترقيات وتدريب وتقويم أداء وإنهاء خدمة وغيرها وفقاً لقانون ولوائح العمل</p>
                </li>
                <li>
                    <p>	الإشراف على تقديم كافة الخدمات الأساسية التي تحتاجها الجمعية لدعم أداء مواردها البشرية</p>
                </li>
                <li>
                    <p>	الإشراف على جميع الأنشطة ذات العلاقة بتعزيز الولاء الوظيفي</p>
                </li>
                <li>
                    <p>	متابعة تنسيق وتأمين مختلف الأنشطة الإعلامية والعلاقات العامة وتطوير أدوات التواصل الفعال مع مختلف الفئات داخل وخارج الجمعية بالتنسيق مع الاقسام الموجودة بالجمعية</p>
                </li>
                <li>
                    <p>	متابعة تطبيق برامج التوعية للعاملين والمتطوعين الجدد حسب السياسات المعتدة من المدير التنفيذي وتعليمات مجلس الادارة </p>
                </li>
                <li>
                    <p>	ضمان استقبال المراجعين من ادارة التضامن والجهات المختصة والرد على استفساراتهم </p>
                </li>
                </ol>
            </ul>

        </div>
    )
}