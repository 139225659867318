import { Link } from 'react-router-dom';
export default function HandMade(){
    return(
        <div className="HandMade">
            <h4>مسئول  <b style={{ color: "#1A5B92", fontSize:"30px", }}>مشروع الاعمال اليدوية</b>      يقوم بالعمل على تأهيل السيدات والفتيات والشباب من الاهالى الراغبين فى إقامة مشاريع ترتبط المشغولات اليدوية والاعمال المرتبطة بالتراث. بغرض تنفيذ مشروعات تدر عليهم ربحاُ مادياً ومكاسب إقتصادية تساعد في زيادة دخل اسرهم  </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p> توفير شباب وسيدات وفتيات يرغبن فى إقامة مشاريع صغيرة جداً بعد اكتساب حرفة من حرف الاعمال اليدوية والتراثية وتجميع البيانات الخاصة بهم فى سجل خاص  </p>
                </li>
                <li>
                    <p>	المساعدة في إعداد دراسات جدوي للمشروعات التي يرغبون في العمل بها</p>
                </li>
                <li>
                    <p>	توفير المدربين المتخصصين فى تأهيل الشباب والسيدات والفتيات الراغبات فى مشاريع ترتبط بالحرف اليدوية والتراثية بغرض تدريبهن علي كيفية ادارة المشروعات واعداد دراسات جدوي لها وعمل ميزانية للمشروع وتسويق المنتجات	</p>
                </li>
                <li>
                    <p>	التواصل والتعاون مع الجهات المتخصصة والجهات المانحة فى مجال مشاريع ترتبط بالحرف اليدوية والتراثية لاسناد مشاريع تتعلق بالمجال لحشد الراغبين في العمل فيه لخدمة الراغبين في اقامة مشاريع متناهية الصغر </p>
                </li>
                </ol>
            </ul>
        <div className="img">
            <div className='imagHa' ></div>
            <p> صور من دورات الخياطه</p>
            <Link to="/الاعمال_يدويه" className='link'> معرفه المزيد </Link>
        </div>
        </div>
    )

}