import Card from "../Component/Card";
import { LoomData } from "./LoomData";

export default function Loom() {
    const dataShow = LoomData.map((item , index) => (
        <div className="card">
            <Card img={item.img} title={item.title} key={index}  />
        </div>
    ));

    return (
        <div>
            <h5 className="title"> دورات النول  </h5>
            <div className="comCard">{dataShow}</div>
        </div>
    );
}