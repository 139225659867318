import React from 'react';
import TeamMember from './TeamMember';

const TeamPage = () => {
  const teamMembers = [
    {
      name: 'رحمه خالد حامد',
      position: 'مسئول قسم الخدمات المسانده',
    //   bio: 'Jane is the CTO of our company...',
     imageUrl: require('../Assests/coo/rahma.jpg'),
    },
    {
      name: 'امل ابو الحسن عبد الرازق',
      position: 'مسئول  قسم الموارد ',
    //   bio: 'John is the CEO of our company...',
      imageUrl: require('../Assests/coo/aml.jpg'),
    },
        {
      name: 'رشا محمد عبد القادر',
      position: 'مسئول قسم المشروعات',
    //   bio: 'Jane is the CTO of our company...',
      imageUrl: require('../Assests/coo/rasha.jpg'),
    },
    {
      name: 'شهد حسين عيد الرحمن',
      position: 'مسئول قسم التنميه والتطوير',
    //   bio: 'Jane is the CTO of our company...',
    imageUrl: require('../Assests/coo/shahd.jpg'),
    },

    
  ];

  return (
    <div className='gg'>
        <h2 className='header'>اعضاء الجمعيه </h2>
    <div className="team-page">
      {teamMembers.map((member, index) => (
        <TeamMember
          key={index}
          name={member.name}
          position={member.position}
          bio={member.bio}
          imageUrl={member.imageUrl}
        />
      ))}
    </div>
    </div>
  );
};

export default TeamPage;