import { Link } from 'react-router-dom';
export default function Activity() {
  return (
    <div className="Activity">
      <div className="container p-4 my-3">
        <h1>الانشطه</h1>
        <div className="event">
          <h2> الزيارات   </h2>
          <p> قامت الجمعيه بالعديد من الزيارات داخل وخارج الجمعيه </p>
          <Link to="/الزيارات" className='link'> معرفه المزيد </Link>
        </div>
          <div className="event">
          <h2> المسرح   </h2>
          <p> قامت الجمعيه بالعديد من العروض المسرحيه داخل وخارج الجمعيه </p>
          <Link to="/المسرح" className='link'> معرفه المزيد </Link>
        </div>
        {/* <div className="event">
          <h2>  البيئه </h2>
          <p> الحفاظ على البيئه من خلال اقامت ندوات </p>
          <Link to="/البيئه" className='link'> معرفه المزيد </Link>
        </div>
        <div className="event">
          <h2>  الرعايه الصحيه </h2>
          <p>الرعايه الصحيه من خلال اقامت الندوات و التوعيه </p>
          <Link to="/الرعايه_الصحيه" className='link'> معرفه المزيد </Link>
        </div>
        <div className="event">
          <h2> المساعدات </h2>
          <p> المساعدات وتضم افطار رمضان </p>
          <Link to="/المساعدات" className='link'> معرفه المزيد </Link>
        </div>
        <div className="event">
          <h2>  زراعه الاسطح واعاده التدوير </h2>
          <p> زراعه الاسطح عن طريق اعاده التدوير و تشجير الاسطح </p>
          <Link to="/زراعه_الاسطح" className='link'> معرفه المزيد </Link>
        </div> */}
      </div>
    </div>
  )
}