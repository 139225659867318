export const HealthyData =[
    {
        img:require("../Assests/Activity2/help/14484992_1746387115610559_5061152149545543979_n.jpg"),
        title:"مساعده الفقراء  ",

    },
    {
        img:require("../Assests/Activity2/help/15622716_1787729064809697_1372707298281876270_n.jpg"),
        title:"مساعده الفقراء  ",

    },
    {
        img:require("../Assests/Activity2/help/20180320_204856.jpg"),
        title:"مساعده الفقراء  ",

    },
    {
        img:require("../Assests/Activity2/help/20181215_164920.jpg"),
        title:"مساعده الفقراء  ",

    },
    {
        img:require("../Assests/Activity2/help/IMG_20181019_143225.jpg"),
        title:"مساعده الفقراء  ",

    },
    {
        img:require("../Assests/Activity2/help/1.jpg"),
        title:"مساعده الفقراء  ",

    },
    {
        img:require("../Assests/Activity2/help/2.jpg"),
        title:"مساعده الفقراء  ",

    },
    {
        img:require("../Assests/Activity2/help/3.jpg"),
        title:"مساعده الفقراء  ",

    },
]