export const VistedData =[

    {
        img:require("../Assests/Activity2/visited/393f8309-33b7-4bf9-bf90-02169c4f5942.jpg"),
        title:" رحله رمضانيه",

    },
    {
        img:require("../Assests/Activity2/visited/2c1a8b79-c62d-4373-9986-7503a87a950d.jpg"),
        title:"    رحله رمضانيه   ",

    },
    {
        img:require("../Assests/Activity2/visited/611d7d07-7557-4fa4-9b82-a658f7ee28c4.jpg"),
        title:" رحله رمضانيه",

    },
    {
        img:require("../Assests/Activity2/visited/fb907cc1-521b-452e-9aaf-80ae173df352.jpg"),
        title:" رحله رمضانيه",

    },
    {
        img:require("../Assests/Activity2/visited/1.jpg"),
        title:" حفله تنميه المواهب",

    },
    {
        img:require("../Assests/Activity2/visited/4.jpg"),
        title:" حفله تنميه المواهب",

    },
    {
        img:require("../Assests/Activity2/visited/7.jpg"),
        title:" حفله تنميه المواهب",
        

    },
    {
        img:require("../Assests/Activity2/visited/growing/IMG-20211231-WA0017.jpg"),
        title:" حفله تنميه المواهب",
        

    },
    {
        img:require("../Assests/Activity2/visited/growing/IMG-20211231-WA0019.jpg"),
        title:" حفله تنميه المواهب",
        

    },
    {
        img:require("../Assests/Activity2/visited/growing/IMG-20211231-WA0024.jpg"),
        title:" حفله تنميه المواهب",
        

    },
    {
        img:require("../Assests/Activity2/visited/growing/IMG_20200131_154313.jpg"),
        title:" حفله تنميه المواهب",
        

    },
    {
        img:require("../Assests/Activity2/visited/growing/mai.jpg"),
        title:" حفله تنميه المواهب",
        

    },
    {
        img:require("../Assests/Activity2/visited/growing/3.jpg"),
        title:" حفله تنميه المواهب",
        

    },
    {
        img:require("../Assests/Activity2/visited/growing/2.jpg"),
        title:" حفله تنميه المواهب",
        

    },
    {
        img:require("../Assests/Activity2/visited/growing/1.jpg"),
        title:" حفله تنميه المواهب",
        

    },
    {
        img:require("../Assests/Activity2/visited/8ef34692-e04d-415e-b35e-e7a6f946e2a2.jpg"),
        title:"حفله تنميه المواهب ",

    },

    {
        img:require("../Assests/Activity2/visited/27336600_2018265458445948_207905603157541650_n.jpg"),
        title:" رحله الاهرامات  ",

    },
    {
        img:require("../Assests/Activity2/visited/27337293_2018265638445930_2852759396909772529_n.jpg"),
        title:" رحله الاهرامات  ",

    },
    {
        img:require("../Assests/Activity2/visited/27459320_2018264988445995_1629832568450287770_n.jpg"),
        title:" رحله الاهرامات  ",

    },
    {
        img:require("../Assests/Activity2/visited/27545461_2018265648445929_831877045805402371_n.jpg"),
        title:" رحله الاهرامات  ",

    },
    {
        img:require("../Assests/Activity2/visited/27337068_2018264968445997_5222994460571765778_n.jpg"),
        title:" رحله الاهرامات  ",

    },
    {
        img:require("../Assests/Activity2/visited/27654895_2018265868445907_686379247625077521_n.jpg"),
        title:" رحله الاهرامات  ",

    },
    {
        img:require("../Assests/Activity2/visited/froq/IMG_20211017_141855_mfnr.jpg"),
        title:"   رحله ركن فاروق",
        

    },
    {
        img:require("../Assests/Activity2/visited/froq/IMG_20211017_142217.jpg"),
        title:"   رحله ركن فاروق",
        

    },
    {
        img:require("../Assests/Activity2/visited/froq/IMG_20211017_142739.jpg"),
        title:"   رحله ركن فاروق",
        

    },
    {
        img:require("../Assests/Activity2/visited/froq/IMG_20211017_143859.jpg"),
        title:"   رحله ركن فاروق",
        

    },
    {
        img:require("../Assests/Activity2/visited/froq/IMG_20211017_143918.jpg"),
        title:"   رحله ركن فاروق",
        

    },
    {
        img:require("../Assests/Activity2/visited/froq/IMG_20211017_144039.jpg"),
        title:"   رحله ركن فاروق",
        

    },

    {
        img:require("../Assests/Activity2/visited/froq/IMG_20211017_144302.jpg"),
        title:"   رحله ركن فاروق",
        

    },
    {
        img:require("../Assests/Activity2/visited/garden/43054650_351391278933972_2074568344698093568_n.jpg"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/43054650_351391278933972_2074568344698093568_n.jpg"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0002.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0007.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0016.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0017.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0023.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0031.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0032.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0035.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0039.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0045.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0053.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0055.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0071.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0073.JPG"),
        title:" رحله للحديقه ",
        
    },
    {
        img:require("../Assests/Activity2/visited/garden/DSC_0074.JPG"),
        title:" رحله للحديقه ",
        
    },



]