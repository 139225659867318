export const cooHData =[
    {
        img:require("../Assests/coo/helwan1.jpg"),
        title:" صور من الشراكه مع  جمعيات حلوان",

    },
        {
        img:require("../Assests/coo/helwan2.jpg"),
        title:" صور من الشراكه مع  جمعيات حلوان",

    },
        {
        img:require("../Assests/coo/helwan3.jpg"),
        title:" صور من الشراكه مع  جمعيات حلوان",

    },
        {
        img:require("../Assests/coo/helwan4.jpg"),
        title:" صور من الشراكه مع  جمعيات حلوان",

    },
        {
        img:require("../Assests/coo/helwan5.jpg"),
        title:" صور من الشراكه مع  جمعيات حلوان",

    },
]