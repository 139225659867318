export const cooData =[
    {
        img:require("../Assests/coo/resala1.jpg"),
        title:" صور من الشراكه مع جمعيه الرساله",

    },
    {
        img:require("../Assests/coo/resala2.jpg"),
        title:" صور من الشراكه مع جمعيه الرساله",

    },
        {
        img:require("../Assests/coo/resala3.jpg"),
        title:" صور من الشراكه مع جمعيه الرساله",

    },
        {
        img:require("../Assests/coo/resala4.jpg"),
        title:" صور من الشراكه مع جمعيه الرساله",

    },
        {
        img:require("../Assests/coo/resala5.jpg"),
        title:" صور من الشراكه مع جمعيه الرساله",

    },
        {
        img:require("../Assests/coo/resala6.jpg"),
        title:" صور من الشراكه مع جمعيه الرساله",

    },
]