export const mediaaData =[
    {
        img:require("../Assests/media/1.jpg"),
        title:"ندوه الاعلام ",

    },
        {
        img:require("../Assests/media/2.jpg"),
        title:"ندوه الاعلام ",

    },
        {
        img:require("../Assests/media/3.jpg"),
        title:"ندوه الاعلام ",

    },
    
]