import './style.css';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';

export default function Header() {
  const [activeItem, setActiveItem] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavCollapse = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleItemClick = (itemId) => {
    setActiveItem(itemId);
    setIsNavOpen(false);
  };

  return (
    <nav className='m-0 col-12'>
      
      <button
        className="navToggle"
        onClick={handleNavCollapse}
        aria-label="Toggle navigation"
      >
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>
      <div className={`navItems ${isNavOpen ? 'open' : ''}`} >
        <div className="btn-group">
        <Link
          to="/"
          className={activeItem === '' ? 'activeNavItem' : 'navItem'}
          onClick={() => handleItemClick('')}
        >
          الصفحة الرئيسية
        </Link>

      </div>

          <div className="btn-group">
          <Link
            id="triggerId5"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            to="/نحن"
            className={activeItem === 'نحن' ? 'activeNavItem' : 'navItem'}
            onClick={() => handleItemClick('نحن')}
          >
            من نحن 
          </Link>
          <div className="dropdown-menu dropdown-menu-center text-center" aria-labelledby="triggerId5">
            <Link className="dropdown-item " to="/الرؤيه">
              الرؤيه والرساله
            </Link>
            <Link className="dropdown-item " to="/اداره">
              مجلس الاداره
            </Link>
            <Link className="dropdown-item " to="/تيم">
            اعضاء الجمعيه
            </Link>
          </div>
          </div>


        <div className="btn-group">
        <Link
          to="/الانشطه"
          className={activeItem === 'الانشطه' ? 'activeNavItem' : 'navItem'}
          onClick={() => handleItemClick('الانشطه')}
        >
          الانشطه
        </Link>
        </div>




        
        <div className="btn-group">
          <Link
            id="triggerId2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            to="/المشروعات"
            className={activeItem === 'المشروعات والتدريب الفنى' ? 'activeNavItem' : 'navItem'}
            onClick={() => handleItemClick('المشروعات والتدريب الفنى')}
          >
            قسم المشروعات
          </Link>
          <div className="dropdown-menu dropdown-menu-center text-center" aria-labelledby="triggerId2">
            <Link className="dropdown-item " to="/الخياطه">
              مشروع الخياطه والتفصيل 
            </Link>
            <Link className="dropdown-item " to="/اعمال_يدويه">
              مشروع الاعمال اليدويه 
            </Link>
            <Link className="dropdown-item " to="/اعاده_تدوير">
              مشروع اعاده التدوير
            </Link>
          </div>
        </div>



        <div className="btn-group">
          <Link
            id="triggerId3"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            to="/الخدمات"
            className={activeItem === 'الاعلام والخدمات المسانده' ? 'activeNavItem' : 'navItem'}
            onClick={() => handleItemClick('الاعلام والخدمات المسانده')}
          >
            قسم الخدمات المسانده
          </Link>
          <div className="dropdown-menu dropdown-menu-center text-center" aria-labelledby="triggerId3">
            <Link className="dropdown-item" to="/المالية">
              المالية
            </Link>
            <Link className="dropdown-item " to="/الموارد_البشريه">
              الموارد البشريه 
            </Link>
            <Link className="dropdown-item " to="/الاعلام">
              الاعلام
            </Link>
            <Link className="dropdown-item " to="/التحويل_الرقمى">
              المعلومات والتحويل الرقمى 
            </Link>
          </div>
        </div>

        
        <div className="btn-group">
          <Link
            id="triggerId1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            to="/الموارد"
            className={activeItem === 'الموارد' ? 'activeNavItem' : 'navItem'}
            onClick={() => handleItemClick('الموارد')}
          >
            قسم الموارد
          </Link>
          <div className="dropdown-menu dropdown-menu-center text-center" aria-labelledby="triggerId1">
            <Link className="dropdown-item " to="/الشراكات">
              
              الشراكات
            </Link>
            <Link className="dropdown-item " to="/التسويق">
              التسويق
            </Link>
          </div>
        </div>

        <div className="btn-group ">
          <Link
            id="triggerId"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            to="/التوفير"
            className={activeItem === 'التنميه و التطوير' ? 'activeNavItem' : 'navItem'}
            onClick={() => handleItemClick('التنميه و التطوير')}
          >
            قسم التنميه و التطوير
          </Link>
          <div className="dropdown-menu dropdown-menu-right text-center" aria-labelledby="triggerId">

            <Link className="dropdown-item " to="/البحوث_الاجتماعيه">
              البحوث والمساعدات 
            </Link>
            <Link className="dropdown-item" to="/التدريب">
              التدريب 
            </Link>
            <Link className="dropdown-item " to="/التوظيف">
              التوظيف
            </Link>
            <Link className="dropdown-item " to="/التوعية">
              التوعيه
            </Link>
          </div>
        </div>

      </div>
      <div className="navImage" alt="logo"></div>
    </nav>
  );
}