

export default function OurUs() {
    return (
        <div className="OurUs mt-5 m-3">
            <h2 className="mb-3"> نبذة عن جمعيه الشرق الخيرية </h2>

            <p>تأسست جمعية الشرق الخيرية عام 2012 .. ومنذ ذلك التاريخ تسعى جمعية الشرق الخيرية الى تقديم الدعم والمساعدة الاجتماعية والاقتصادية والصحية الخ .. للفئات المهمشة والمحتاجة للرعاية من ذوي الهمم والفقراء والمرضى حيث يقع مقر الجمعية فى منطقة عرب غنيم والملاءة بحلوان وهى من المناطق التى يقطن بها العديد من الاسر الفقيرة والاولى بالرعاية وتحتاج الى تكاتف بين الجهات الرسمية ومنظمات المجتمع المدني للنهوض باهالى المنطقة وبث روح العمل والانتاجيه </p>
            <p>
                تهدف الجمعية الى إقامة المشروعات متناهية الصغر جديدة وكذلك بتقديم الدعم لمشروعات قائمة بالفعل والعمل على مساعدة الحالات مع استعادة النشاط بشراء البضاعة أو الالات والمعدات اللازمة كما تعمل الجمعية على دعم هذه الفئة من الحالات التى تملك امكانية العمل بمشروع انتاجى او خدمى يعود على العميل نفسه وعلى المجتمع المحيط بالنفع  </p>

            <p>
                تعمل جمعية الشرق الخيرية علي اقامة المشروعات الانتاجية والخدمية بالجمعية بحيث تقام مشروعات بالجمعية تهدف الى تشغيل السيدات والفتيات او الشباب الراغبين فى العمل .. وذلك نظير مقابل مادى لهم يساعدهم فى ادة دخل اسرهم .. وذلك مثل مشغل للخياطة والسجاد النول والاعمال اليدوية والكراسي من البراميل والكاوتشات... الخ

            </p>
            <p>
                كما تعمل الجمعية على رعاية الفئات الخاصة والمعوقين بما يضمن لهم عيشة كريمة ومستوى أفضل من الناحية الصحية والاقتصادية والاجتماعية وتقديم جلسات تخاطب وتنمية قدراتهم وإخراج مواهبهم الفنية وتدريبهم علي أعمال فنية ومسرحية </p>

            <p> تعمل الجمعية على نشر التوعية الثقافية والعلمية فى المجتمع المحلى وبث روح التسامح والتعاون المشترك وحب الوطن.. وذلك بإقامة المحاضرات والندوات التوعوية التى تسعى الى زرع ونشر المفاهيم الايجابية بين الاهالى
            </p>
            
        </div>
    )
}