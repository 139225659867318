
import Card from "../Component/Card";
import { hummanData } from "./hummanData";
export default function Healthy(){


    
            const dataShow = hummanData.map((item , index) => (
                <div className="card">
                    <Card img={item.img} title={item.title}  key={index}/>
                </div>
            ));
            return (
                <div>
                    <h5 className="title">   الموارد البشريه  </h5>
                    <div className="comCard">{dataShow}</div>
                </div>
            )
        
}