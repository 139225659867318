
import React from 'react';
import TeamMember from './TeamMember';

const Admin = () => {
  const teamMembers = [
    {
      name: 'صالح عمران ',
      position: 'رئيس مجلس الاداره ',
      imageUrl: require('../Assests/coo/saleh.jpg'),
    },
    {
      name: ' محمد عمران  ',
      position: ' نائب رئيس مجلس الاداره ',
      imageUrl: require('../Assests/coo/mohamed.jpg'),
    },
    {
      name: 'منى سعد ',
      position: '  الامين العام  ',
      imageUrl: require('../Assests/coo/mona.jpg'),
    },
    {
      name: ' فاطمه مسعد  ',
      position: '  امين الصندوق ',
      imageUrl: require('../Assests/coo/fatma.jpg'),
    },
    {
      name: ' حسن ابراهيم',
      position: '  العضو الفنى ',
      imageUrl: require('../Assests/coo/hassan.jpg'),
    },
    
  ];

  return (
    <div className='gg'>
        <h2 className='header'>اعضاء مجلس الاداره  </h2>
    <div className="admin-page">
      {teamMembers.map((member, index) => (
        <TeamMember
          key={index}
          name={member.name}
          position={member.position}
          bio={member.bio}
          imageUrl={member.imageUrl}
        />
      ))}
    </div>
    </div>
  );
};

export default Admin;