export const StageData =[

    {
        img:require("../Assests/stage/1.jpg"),
        title:"فريق مسرح الشرق يقدم عروضه علي مسرح السامر بالعجوزة",
    },
    {
        img:require("../Assests/stage/2.jpg"),
        title:"فريق مسرح الشرق يقدم عروضه علي مسرح السامر بالعجوزة",
    },
    {
        img:require("../Assests/stage/3.jpg"),
        title:"فريق مسرح الشرق يقدم عروضه علي مسرح السامر بالعجوزة",
    },
    {
        img:require("../Assests/stage/4.jpg"),
        title:"فريق مسرح الشرق يقدم عروضه علي مسرح السامر بالعجوزة",
    },
    {
        img:require("../Assests/stage/5.jpg"),
        title:"فريق مسرح الشرق يقدم عروضه علي مسرح السامر بالعجوزة",
    },
    {
        img:require("../Assests/stage/6.jpg"),
        title:"فريق مسرح الشرق يقدم عروضه علي مسرح السامر بالعجوزة",
    },
    {
        img:require("../Assests/stage/7.jpg"),
        title:"فريق مسرح الشرق يقدم عروضه علي مسرح السامر بالعجوزة",
    },
    {
        img:require("../Assests/stage/8.jpg"),
        title:"فريق مسرح الشرق يقدم عروضه علي مسرح السامر بالعجوزة",
    },
    {
        img:require("../Assests/stage/stage1.jpg"),
        title:" تدريبات المسرح لذوي الاحتياجات الخاصة  ",

    },
    {
        img:require("../Assests/stage/stage2.jpg"),
        title:" تدريبات المسرح لذوي الاحتياجات الخاصة ",

    },
    {
        img:require("../Assests/stage/stage3.jpg"),
        title:" تدريبات المسرح لذوي الاحتياجات الخاصة ",

    },
    {
        img:require("../Assests/stage/stage4.jpg"),
        title:" تدريبات المسرح لذوي الاحتياجات الخاصة ",

    },
    {
        img:require("../Assests/stage/stage5.jpg"),
        title:" تدريبات المسرح لذوي الاحتياجات الخاصة ",

    },
    {
        img:require("../Assests/stage/stage6.jpg"),
        title:" تدريبات المسرح لذوي الاحتياجات الخاصة ",

    },
    {
        img:require("../Assests/stage/stage7.jpg"),
        title:" تدريبات المسرح لذوي الاحتياجات الخاصة ",

    },

]