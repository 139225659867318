
import './Styles/mainStyle.css';

import { Route, Routes} from 'react-router-dom';
import Header from './Component/Header';
import Home from './Pages/Home';
import Footer from './Component/Footer';
import Care from './Pages/Care';
import Traning from './Pages/Traning';
import Jobs from './Pages/Jobs';
import OurUs from './Pages/OurUs';
import Projects from './Pages/Projects';
import Computer from './Train/Computer';
import Loom from './Train/Loom';
import Sewing from './Train/Sewing';
import Stage from './Activitys/Stage';
import Activity from './Activitys/Activity';
import Mediaa from './Activitys/mediaa';
import Help from './Activitys/Help';
import Selll from './Activitys/selll';
import Humman from './Activitys/humman';
import Visited from './Activitys/Visited';
import Environment from './Activitys/Environment';
import Healthy from './Activitys/Healthy';
import Growing from './Activitys/Growing';
import Coo from './Activitys/coo';
import CooH from './Activitys/cooH';
import Woman from './Pages/Woman';
import NoEdu from './Pages/NoEdu';
import Money from './Pages/Money';
import Media from './Pages/Media';
import DigitalConversion from './Pages/DigitalConversion';
import HumanResourse from './Pages/HumanResourse';
import SocialReserch from './Pages/SocialReserch';
import Evaluation from './Pages/Evaluation';
import Services from './Pages/Services';
import Product from './Pages/Product';
import Companies from './Pages/Companies';
import Shopping from './Pages/Shopping';
import MakeThings from './Pages/MakeThings';
import Replacement from './Pages/Replacement';
import HandMade from './Pages/HandMade';
import Seeing from './Pages/Seeing';
import TeamPage from './Pages/TeamPage';
import Admin from './Pages/Admin';

export default function App() {
  return (
    <div>
      
      <Header/>
      <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/نحن" element={<OurUs/>}/>
      <Route path="/التوعية" element={<Care/>}  />
      <Route path="/التدريب" element={<Traning/>} />
      <Route path='/كمبيوتر' element={<Computer/>} />
      <Route path='/خياط' element={<Sewing/>} />
      <Route path='/النول' element={<Loom/>} />
      <Route path='/المسرح' element={<Stage/>} />
      <Route path="/التوظيف" element={<Jobs/>} />
      <Route path="/المشروعات" element={<Projects/>} />
      <Route path="/الانشطه" element={<Activity/>} />
      <Route path="/المساعدات" element={<Help/>} />
      <Route path="/الرعايه_الصحيه" element={<Healthy/>} />
      <Route path="/الزيارات" element={<Visited/>} />
      <Route path="/الاعمال_يدويه" element={<Environment/>} />
      <Route path="/اعاده_التدوير" element={<Growing/>} />
      <Route path="/العنف_ضد_المراه" element={<Woman/>} />
      <Route path=  "/محو_الاميه"  element={<NoEdu/>} />
      <Route path=  "/المالية"   element={<Money/>} />
      <Route path=  "/الاعلام"  element={<Media/>} />
      <Route path=  "/التحويل_الرقمى"  element={<DigitalConversion/>} />
      <Route path=  "/الموارد_البشريه"  element={<HumanResourse/>} />
      <Route path=  "/البحوث_الاجتماعيه"  element={<SocialReserch/>} />
      <Route path=  "/التوفير"  element={<Evaluation/>} />
      <Route path=  "/الخدمات"  element={<Services/>} />
      <Route path=  "/الموارد"  element={<Product/>} />
      <Route path=  "/الشراكات"  element={<Companies/>} />
      <Route path=  "/التسويق"  element={<Shopping/>} />
      <Route path=  "/اعمال_يدويه"  element={<HandMade/>} />
      <Route path=  "/اعاده_تدوير"  element={<Replacement/>} />
      <Route path=  "/الخياطه"  element={<MakeThings/>} />
      <Route path=  "/اعلام"  element={<Mediaa/>} />
      <Route path=  "/تسويق"  element={<Selll/>} />
      <Route path=  "/موارد_بشريه"  element={<Humman/>} />
      <Route path=  "/شراكات_الرساله"  element={<Coo/>} />
      <Route path=  "/شراكات_حلوان"  element={<CooH/>} />
      <Route path=  "/الرؤيه"  element={<Seeing/>} />
      <Route path=  "/تيم"  element={<TeamPage/>} />
      <Route path=  "/اداره"  element={<Admin/>} />
      
      


      
      </Routes>
      <Footer/>
    </div>
  );
}

