import Card from "../Component/Card";
import { cooHData } from "./cooHData";
export default function cooH(){

                    const dataShow = cooHData.map((item, index) => (
                        <div  className="card">
                            <Card img={item.img} title={item.title}  key={index} />
                        </div>
                    ));
                    return (
                        <div>
                            <h5 className="title"> صور من الشراكه مع جمعيات حلوان   </h5>
                        <div className="comCard">{dataShow}</div>
                        </div>
                    )
                
        
    }