
import Card from "../Component/Card";
import { VistedData } from "./VistedData";
export default function Visited(){
    const dataShow = VistedData.map((item, index) => (
        <div className="card">
            <Card img={item.img} title={item.title}  key={index} />
        </div>
    ));
    return (
        <div className="contant">
        <h5 className="title"> الزيارات </h5> 
        <div className="comCard">{dataShow}</div>
        </div>
    )
}