export const EnvironmentData =[
    {
        img:require("../Assests/handmad/1.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },

        {
        img:require("../Assests/handmad/2.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },
        {
        img:require("../Assests/handmad/3.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },
        {
        img:require("../Assests/handmad/4.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },
        {
        img:require("../Assests/handmad/5.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },
        {
        img:require("../Assests/handmad/6.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },
        {
        img:require("../Assests/handmad/7.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },
        {
        img:require("../Assests/handmad/8.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },
        {
        img:require("../Assests/handmad/9.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },
        {
        img:require("../Assests/handmad/10.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },
        {
        img:require("../Assests/handmad/11.jpg"),
        title:" صور من دورات الاعمال اليدويه",

    },
]