import { Link } from 'react-router-dom';
export default function DigitalConversion(){
    return (
        <div className="DigitalConversion">
            <h4>  المهمة الرئيسية لمسؤول <b style={{ color: "#1A5B92", fontSize:"30px", }}>تقنية المعلومات والتحول الرقمي</b> هو  تنفيذ أعماله الوظيفية باستخدام التكنولوجيا والأعمال والحاسوب سويًا وذلك بهدف إتمام الأعمال الإدارية داخل الشركة علي أكمل وجه   خبرة في إدارة أنظمة المعلومات التكنولوجية هذا بالإضافة إلى القدر الكافي من الخبرة في إدارة الأعمال  </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>اعداد والاشراف علي موقع للجمعية علي النت وصيانته وتطويره ومتابعة تحديث بيانات الجمعية علي الموقع</p>
                </li>
                <li>
                    <p>تدريب، وإرشاد، وتوجيه الموظفين، وضبطهم والإشراف عليهم فيما يخص أعمال الكمبيوتر واذخال البيانات </p>
                </li>
                <li>
                    <p>تقييم نتائج أنظمة المعلومات واختبار التطبيقات والبرمجيات</p>
                </li>
                <li>
                    <p>	حماية المعدات والمكونات والبرمجيات من التلف وتوفير النسخ الاحتياطية  وتوفير أنظمة حماية أمن المعلومات</p>
                </li>
                <li>
                    <p>	وضع جداول تُبيِّن المصاريف والنفقات </p>
                </li>
                <li>
                    <p>	متابعة كفاءة وأداء الأجهزة الإلكترونية مثل أجهزة الحاسوب، والطابعات</p>
                </li>
                <li>
                    <p> تنظيم الفعاليات المختلفة التي تتبناها الجمعية  والترتيب المناسب لها لتظهر في أفضل صورة</p>
                </li>
                </ol>
            </ul>
            <div className="img">
            <div className='imgeD' ></div>
            <p> صور من دورات الكمبيوتر</p>
            <Link to="/كمبيوتر" className='link'> معرفه المزيد </Link>
        </div>

        </div>
    )
}