export default function Evaluation(){
    return(
        <div className="Evaluation">
            <h2> قسم التنمية والتطوير : </h2>
            <h4> وضع الخطط التي تخص التوعية في المجالات الاقتصادية والاجتماعية والثقافية والبيئية ..وكذلك التدريب علي الحرف خاصة اليدوية والتراثية والمتعلقة بالمحافظة علي البيئة  وخطة البحوث الدورية للمستحقين لخدمات الجمعية.. ومحاربة البطالة بتشغيل راغبي العمل  </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>	وضع خطة المؤتمرات والندوات ومحاضرات التوعية في المجالات العلمية والاقتصادية والاجتماعية والثقافية والبيئية للتوعية المجتمعية</p>
                </li>
                <li>
                    <p>	اعداد خطة لنشر مبادئ التسامح والتعاون في المجتمع المحلي والبيئة المحيطة</p>
                </li>
                <li>
                    <p>	وضع خطة لتعريف الاهالي وتحفيزهم خاصة فئة الشباب والاطفال الي الاطلاع والاستعارة من المكتبة والالتحاق بدورات الحاسب الآلي داخل وخارج الجمعية والتوعية بأهمية ومخاطر التحول الرقمي والميتافيرس</p>
                </li>
                <li>
                    <p>	وضع خطة لتدريب السيدات والفتيات والشباب من الاهالى لإكسابهم المهارات المعرفية والفنية والحرفية والاعمال اليدوية التي يحتاجها السوق المحلي بهدف زيادة دخل الاسرة</p>
                </li>
                <li>
                    <p> اعداد الخطط اللازمة لتحفيز المتبرعين من الافراد والجهات المختلفة للمساهمة في زيادة ايرادات الجمعية واعداد خطة للاستكشافات والبحوث علي المستفيدين وخطة تقديم المساعدات علي المستحقين بالجمعية </p>
                </li>
                <li>
                    <p>	وضع خطة لإيجاد فرص عمل للراغبين ووضع الاليات التي تساعد علي الحاق العاطلين بوظائف شاغرة لدي اصحاب الأعمال بالمناطق المجاورة للتقليل من الآثار السلبية للبطالة</p>
                </li>
                </ol>
            </ul>

        </div>
    )
}