import { Link } from 'react-router-dom';
export default function Media(){
    return(
        <div className="Media">
            <h4> المسئول عن  <b style={{ color: "#1A5B92", fontSize:"30px", }}> وحدة الاعلام </b>  يكون مسئول علي معرفة وافية  بانشاء الجمعيات من الناحية القانونية والجهات المختلفة التي تتعامل معها ويكون لديه رؤية لتكوين صورة مؤثرة وفعالة للجمعية   </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>رسم صورة قوية وذات فعالية ومؤثرة للجمعية والتسويق لهذة الصورة لتأكيدها لدي الجهات المختلفة</p>
                </li>
                <li>
                    <p>	يمسك بسجل واضح للجهات المؤثرة وأصحاب المصالح المتعلقة بالجمعية</p>
                </li>
                <li>
                    <p>	دائم التواصل مع الجهات المختلفة  والتعامل مع أخر التطورات في الساحة حتي لاتتخلف الجمعية عن الركب</p>
                </li>
                <li>
                    <p>	مراقبة موقع الجمعية الالكتروني وتحديثه وكذلك المواقع  التي تخص الجمعية علي منصات التواصل الاجتماعي مثل فيس بوك وتويتر وغيرها	</p>
                </li>
                <li>
                    <p> نشر المعلومات والفعاليات والمناسبات التي تقوم بها الجمعية  في اطار رؤية الجمعية  </p>
                </li>
                <li>
                    <p>الاستفادة من امكانيات التحول الرقمي الحديث في تحسين صورة الجمعية</p>
                </li>
                <li>
                    <p> تنظيم الفعاليات المختلفة التي تتبناها الجمعية  والترتيب المناسب لها لتظهر في أفضل صورة</p>
                </li>
                </ol>
            </ul>
            <div className="img">
            <div className='imgem' ></div>
            <p> صور من ندوات الاعلام</p>
            <Link to="/اعلام" className='link'> معرفه المزيد </Link>
        </div>
        </div>
    )

}