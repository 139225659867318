import { Link } from "react-router-dom";

export default function Traning() {
    return (
        <div className="traning ">
            <h4>المسئول عن   <b style={{ color: "#1A5B92", fontSize:"30px", }}>التدريب</b>   يقوم بالعمل على تدريب السيدات والفتيات والشباب من الاهالى لإكتسابهم المهارات المعرفية والفنية والحرفية التي يحتاجها السوق.ليتمكنوا في النهاية من ادارة وتنفيذ مشروعات تدر عليهم ربحاُ مادياً ومكاسب إقتصادية تساعد في زيادة دخل اسرهم </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية :</b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>التعاقذ  مع المدربين  والاتفاق على اقامة دورات تدريبية فى مجالات الخياطة  و. النول   الكروشيه الخ من الاعمال اليدوية والتدوير </p>
                </li>
                <li>
                    <p>	تجميع المتدربين الراغبين فى اكتساب حرفة معينة  فى سجل المتدربين والعدد المناسب  والسن والمؤهل اذا تطلب الامر</p>
                </li>
                <li>
                    <p>	البحث  والاتفاق مع الجهات المتخصصة من خارج الجمعية والتى تعمل فى مجال التدريب للحرف المطلوبة</p>
                </li>
                <li>
                    <p>	وضع الخطط اللازمة  للدورات التدريبية بالجمعية  والتكلفة  والجهات والافراد المطلوبة .. ومتابعة تنفيذها</p>
                </li>
                <li>
                    <p>القيام بالجرد الدوري للخزينة و إمساك و حفظ جميع المستندات والدفاتر المالية</p>
                </li>
                </ol>
            </ul>
            <div className="img">
            <div className='imgeTra' ></div>
            <p> صور من دورات النول</p>
            <Link to="/النول" className='link'> معرفه المزيد </Link>
        </div>
            
        </div>
    )
}