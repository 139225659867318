export const LoomData =[
    {
        img:require("../Assests/loom/loom1.jpg"),
        title:" تدريبات النول لذوي الاحتياجات الخاصة  ",

    },
    {
        img:require("../Assests/loom/loom2.jpg"),
        title:" تدريبات النول لذوي الاحتياجات الخاصة ",

    },
    {
        img:require("../Assests/loom/loom3.jpg"),
        title:" تدريبات النول لذوي الاحتياجات الخاصة ",

    },
]