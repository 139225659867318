import Card from "../Component/Card";
import { GrowingData } from "./GrowingData";
export default function Growing(){

    
    
        
                const dataShow = GrowingData.map((item, index) => (
                    <div  className="card">
                        <Card img={item.img} title={item.title}  key={index} />
                    </div>
                ));
                return (
                    <div>
                        <h5 className="title">  زراعه الاسطح واعاده التدوير </h5>
                      <div className="comCard">{dataShow}</div>
                    </div>
                )
            
    
}