

import Card from "../Component/Card";
import { HealthyData } from "./HealthyData";
export default function Healthy(){


    
            const dataShow = HealthyData.map((item , index) => (
                <div className="card">
                    <Card img={item.img} title={item.title}  key={index}/>
                </div>
            ));
            return (
                <div>
                    <h5 className="title">  الرعايه الصحيه </h5>
                  <div className="comCard">{dataShow}</div>
                </div>
            )
        
}