import Card from "../Component/Card";
import { ComputerData } from "./ComputerData";

export default function Computer() {
    const dataShow = ComputerData.map((item, index) => (
        <div className="card">
            <Card img={item.img} title={item.title} key={index}/>
        </div>
    ));

    return (
        <div>
            <h5 className="title"> دورات الكمبيوتر </h5>
            <div className="comCard">{dataShow}</div>
        </div>
    );
}