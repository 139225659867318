import Card from "../Component/Card";
import { NoEduData } from "./NoEduData";
export default function NoEdu(){
           
            
                    const dataShow = NoEduData.map((item, index) => (
                        <div  className="card">
                            <Card img={item.img} title={item.title}  key={index} />
                        </div>
                    ));
                    return (
                        <div>
                            <h5 className="title">   محو الامية  </h5>
                          <div className="comCard">{dataShow}</div>
                        </div>
                    )
                
        
    }