export const NoEduData =[
    {
        img:require("../Assests/noEdu/1.jpg"),
        title:" ",

    },

    {
        img:require("../Assests/noEdu/2.jpg"),
        title:" ",

    
    }, {
        img:require("../Assests/noEdu/3.jpg"),
        title:" ",

    },
    {
        img:require("../Assests/noEdu/4.jpg"),
        title:" ",

    
    },
    {
        img:require("../Assests/noEdu/5.jpg"),
        title:" ",

    
    },

    {
        img:require("../Assests/noEdu/6.jpg"),
        title:" ",

    },
    {
        img:require("../Assests/noEdu/7.jpg"),
        title:" ",

    },
    {
        img:require("../Assests/noEdu/8.jpg"),
        title:" ",

    },
    {
        img:require("../Assests/noEdu/9.jpg"),
        title:" ",

    },
]