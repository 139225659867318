import { Link } from 'react-router-dom';
export default function Replacement(){
    return(
        <div className="Replacement">
            <h4>المسئول عن  <b style={{ color: "#1A5B92", fontSize:"30px", }}>مشروع اعادة التدوير</b>     يتولي العمل نحو تجميع الشباب والسيدات والذين يمتلكون أفكاراً أو دراسات جدوي أو توجيههم الي مشروعات صغيرة جداً تدخل في مجال اعادة تدوير المخلفات وحماية البيئة  </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>	البحث عن شباب وسيدات يرغبن فى اكتساب مهن وحرف تتعلق بالبيئة واعادة تدوير المخلفات وتجميع البيانات الخاصة بهم فى سجل خاص لتدريب الراغبين علي تنفيذ مشروعات بيئية لخدمة اهالى المنطقة </p>
                </li>
                <li>
                    <p>	 التدريب للراغبين داخل الجمعية عن طريق خبراء و اسطوات فى المهن المتعلقة باعادة التدوير مثل نجار/ حداد /منجد الخ .. ونقل الخبرة الى الشباب الراغبين </p>
                </li>
                <li>
                    <p>	التواصل والتعاون مع الجهات المانحة والجهات المتخصصة فى مجال البيئة وإعادة التدويربغرض تنفيذ مشروعات بيئية للراغبين من الشباب والسيدات لخدمة اهالى المنطقة.. مثل زراعة الاسطح  - تدوير الملابس – تدوير كاوتشات السيارات – النول وصناعة السجاد   - الصابون وصناعة المنظفات  - تدوير الورق وغيرها من المشروعات التي تحافظ علي البيئة</p>
                </li>
                <li>
                    <p>	اعداد الخطط والبرامج واستيفاء النماذج الخاصة بالمشاريع التي تعرضها الجهات المانحة والتعاون مع المتخصصين في هذا المجال حتي يتسني الحصول علي المنح بهذه المشاريع التي تخدم أهالي المنطقة </p>
                </li>
                </ol>
            </ul>
            <div className="img">
            <div className='imgeRe' ></div>
            <p> صور من اعاده التدوير</p>
            <Link to="/اعاده_التدوير" className='link'> معرفه المزيد </Link>
        </div>
        </div>
    )

}