export default function Money(){
    return(
        <div className="money">
            <h4>المسئول  <b style={{ color: "#1A5B92", fontSize:"30px", }}>المالي</b>   يقوم بالعمل علي تنفيذ الأهداف التنظيمية و متابعة للخطط الخاصة بالإدارة المالية حسب اللوائح المنظمة و الإجراءات المعتمدة. وقيادة  فريق العمل ونقل خبرته له بما يؤمن الجانب المالي ويقويه  و العمل علي تقديم الدعم لكافة أقسام الجمعية </h4>
            <p><b> يقوم بتنفيذ المهام والمسئوليات التالية : </b></p>
            <ul>
                <ol type="1">
                <li>
                    <p>تقديم الدعم لكافة أقسام الجمعية وحصر الاحتياج من اثاث وأجهزة وسلف ومتابعة تأمينه وتسجيل العهد وإنهاء جميع الاجراءات اللازمة </p>
                </li>
                <li>
                    <p>	مراجعة جميع مستندات الصرف واعتمادها و إصدار أمر الصرف</p>
                </li>
                <li>
                    <p>	اعداد الميزانية السنوية وفقاً لمستندات الصرف والسجلات المالية</p>
                </li>
                <li>
                    <p>	اعداد الموازنة  المقترحة  للسنة المالية القادمة  فى حدود الإمكانيات المتاحة </p>
                </li>
                <li>
                    <p>القيام بالجرد الدوري للخزينة و إمساك و حفظ جميع المستندات والدفاتر المالية</p>
                </li>
                <li>
                    <p>حفظ نسخ من جميع المستندات المالية والسجلات المالية للجمعية</p>
                </li>
                <li>
                    <p>رفع التقارير الأسبوعية والشهرية و السنوية للمدير التنفيذي و مناقشة هذه التقارير فى إجتماعات الإدارة العليا الدورية</p>
                </li>
                </ol>
            </ul>
        </div>
    )

}